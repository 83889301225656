<template>
  <v-app>
    <v-app-bar app color="green" dark>
      <v-app-bar-nav-icon
        v-if="$route.name != 'Home' && $route.name != 'SelecionaMedico' && $route.name != 'SelecionaExame'" 
        @click="() => $router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title v-if="$route.params.profissional">
        Clínica ECON - {{ $route.params.profissional.nome }}
      </v-toolbar-title>
      <v-toolbar-title v-else> Clínica ECON </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>
